import { setup } from 'src/knowledge/setup'
import Knowledge, { query } from 'src/knowledge/service'
import { pagePropsFor } from 'src/templates/pageUtils'
import Page from 'src/components/page'
import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { prefixer } from 'stylis'
import rtlPlugin from 'stylis-plugin-rtl'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'


export default function PageTemplate(props) {
  let context = props.pageContext
  let language = context.language
  Knowledge.current.language = language.id // sync server and client
  setup()

  let page = pagePropsFor(context)
  Knowledge.current.page = page.path

  // TODO: extract =IL= into a current territory variable
  let fullIso = `${context.language.id}-IL`
  Knowledge.current.locale = fullIso

  React.useEffect(() => {
    document.body.setAttribute('dir', language.dir)
  }, [language.dir])

  let caches = {
    ltr: createCache({
      key: 'muiltr',
      stylisPlugins: [prefixer],
    }),
    rtl: createCache({
      key: 'muirtl',
      stylisPlugins: [prefixer, rtlPlugin],
    }),
  }

  return (
    <>
      <React.StrictMode>
        <GatsbySeo
          title={page.title}
          language={fullIso}
          htmlAttributes={{ lang: fullIso, dir: language.dir }}
          openGraph={Knowledge.query('/site/meta_tags/$W_opengraph')}
        />
        <CacheProvider value={caches[language.dir]}>
          <ThemeProvider theme={Knowledge.theme}>
            <CssBaseline enableColorScheme={query('/site/flags/enable_css_color_scheme')} />
            <Page page={page} />
          </ThemeProvider>
        </CacheProvider>
      </React.StrictMode>
    </>
  )
}
